import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import Application from "../components/Application"
import Image from "../images/hero.png"
import styled from "styled-components"
import ProductPageComponent from "../components/ProductPageComponent"

import { Helmet } from "react-helmet"
import SEO from "../components/seo"

import DiskImage1 from "../images/k42_0003_k42-view-1.jpg"
import DiskImage2 from "../images/k42_0002_k42-view-2.jpg"
import DiskImage3 from "../images/k42_0000_k42-view-4.jpg"
import DiskImage4 from "../images/k42_0001_k42-view-3.jpg"

import CylindricalImage1 from "../images/k42_0003_k42-view-1.jpg"
import CylindricalImage2 from "../images/k42_0002_k42-view-2.jpg"
import CylindricalImage3 from "../images/k42_0000_k42-view-4.jpg"
import CylindricalImage4 from "../images/k42_0001_k42-view-3.jpg"

import OrbitalImage1 from "../images/k42_0003_k42-view-1.jpg"
import OrbitalImage2 from "../images/k42_0002_k42-view-2.jpg"
import OrbitalImage3 from "../images/k42_0000_k42-view-4.jpg"
import OrbitalImage4 from "../images/k42_0001_k42-view-3.jpg"

import DiskImage1Large from "../images/k42_0003_k42-view-1.jpg"
import DiskImage2Large from "../images/k42_0002_k42-view-2.jpg"
import DiskImage3Large from "../images/k42_0000_k42-view-4.jpg"
import DiskImage4Large from "../images/k42_0001_k42-view-3.jpg"

import CylindricalImage1Large from "../images/k42_0003_k42-view-1.jpg"
import CylindricalImage2Large from "../images/k42_0002_k42-view-2.jpg"
import CylindricalImage3Large from "../images/k42_0000_k42-view-4.jpg"
import CylindricalImage4Large from "../images/k42_0001_k42-view-3.jpg"

import OrbitalImage1Large from "../images/k42_0003_k42-view-1.jpg"
import OrbitalImage2Large from "../images/k42_0002_k42-view-2.jpg"
import OrbitalImage3Large from "../images/k42_0000_k42-view-4.jpg"
import OrbitalImage4Large from "../images/k42_0001_k42-view-3.jpg"

import CylindricalImageThumb from "../images/cylindrical-3d-small.jpg"
import DiskImageThumb from "../images/disk-thumb.jpg"
import OrbitalImageThumb from "../images/orbital-thumb.jpg"
import OrbitalImage from "../images/orbital-deck-400.png"
import CylindricalImage from "../images/cylindrica-deck-imagel-400x.png"
import DiskImage from "../images/disk-deck-400.png"

const K24PageMain = styled.main`
  padding: 2rem;
`

const RiderSeriesPage = props => {
  return (
    <>
      <SEO title="Floor Scrubbers: K25 Walk-Behind Industrial Cleaning Equipment - Kodiak Cleaning Equipment" />
      <MainLayout>
        <PageHeading
          pageHeading="K42 Floor Scrubbers"
          // description="Here is the info about the K3 scrubbers and what they are used for"
        />
        <K24PageMain>
          <ProductPageComponent
            chooseTitle="Choose Your Scrub Deck"
            descriptionTitle1="Solution Tank Capacity:"
            descriptionTitle2="Run Time:"
            mainDiskImage={DiskImage1Large}
            mainCylindricalImage={CylindricalImage1Large}
            mainOrbitalImage={OrbitalImage1Large}
            thumbDiskImage1={DiskImage1}
            thumbDiskImage2={DiskImage2}
            thumbDiskImage3={DiskImage3}
            thumbDiskImage4={DiskImage4}
            thumbCylindricalImage1={CylindricalImage1}
            thumbCylindricalImage2={CylindricalImage2}
            thumbCylindricalImage3={CylindricalImage3}
            thumbCylindricalImage4={CylindricalImage4}
            thumbOrbitalImage1={OrbitalImage1}
            thumbOrbitalImage2={OrbitalImage2}
            thumbOrbitalImage3={OrbitalImage3}
            thumbOrbitalImage4={OrbitalImage4}
            DiskImage1Large={DiskImage1Large}
            DiskImage2Large={DiskImage2Large}
            DiskImage3Large={DiskImage3Large}
            DiskImage4Large={DiskImage4Large}
            CylindricalImage1Large={CylindricalImage1Large}
            CylindricalImage2Large={CylindricalImage2Large}
            CylindricalImage3Large={CylindricalImage3Large}
            CylindricalImage4Large={CylindricalImage4Large}
            OrbitalImage1Large={OrbitalImage1Large}
            OrbitalImage2Large={OrbitalImage2Large}
            OrbitalImage3Large={OrbitalImage3Large}
            OrbitalImage4Large={OrbitalImage4Large}
            diskTitle="K25 Disk"
            subTitle="Walk-Behind Large Floor Scrubber"
            diskDescription="Clean for extended hours with the K25 high-performance Battery Floor Scrubber.  This floor scrubber is equipped with large tanks for large area cleaning.  Take control of your facility cleaning and provide consistent results for years.  The K25 is designed around a steel frame to ensure durability while being budget friendly.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  Premium performance with your budget in mind."
            diskCleaningPath="28 - 32in (71 - 81cm)"
            diskGallons="Sol: 25gal (94l) Rec: 26gal (98l)"
            diskRunTime=" Up to 3 hours"
            cylindricalTitle="K25 Cylindrical"
            cylindricalDescription="Clean for extended hours with the K25 high-performance Battery Floor Scrubber.  This floor scrubber is equipped with large tanks for large area cleaning.  Take control of your facility cleaning and provide consistent results for years.  The K25 is designed around a steel frame to ensure durability while being budget friendly.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  Premium performance with your budget in mind."
            cylindricalCleaningPath="28 - 32in (71 - 81cm)"
            cylindricalGallons="Sol: 25gal (94l) Rec: 26gal (98l)"
            cylindricalRunTime=" Up to 3 hours"
            orbitalTitle="K25 Orbital"
            orbitalDescription="Clean for extended hours with the K25 high-performance Battery Floor Scrubber.  This floor scrubber is equipped with large tanks for large area cleaning.  Take control of your facility cleaning and provide consistent results for years.  The K25 is designed around a steel frame to ensure durability while being budget friendly.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  Premium performance with your budget in mind."
            orbitalCleaningPath="28 - 32in (71 - 81cm)"
            orbitalGallons="Sol: 25gal (94l) Rec: 26gal (98l)"
            orbitalRunTime=" Up to 3 hours"
            DeckImageThumb1={DiskImageThumb}
            DeckImageThumb2={CylindricalImageThumb}
            DeckImageThumb3={OrbitalImageThumb}
            DeckImage1Description="Disk"
            DeckImage2Description="Cylindrical"
            DeckImage3Description="Orbital"
            DeckImageLarge1={DiskImage}
            DeckImageLarge2={CylindricalImage}
            DeckImageLarge3={OrbitalImage}
            // deckTitle="Why Disk"
            deckDescription1="Wavy / Irregular Floors"
            deckDescription2="Lowest Maintenance Cost"
            deckDescription3="Allows Pads"
            deckTitle2="Why Cylindrical"
            deckDescription4="Scrub & Sweep Simultaneously"
            deckDescription5="Tile & Grout Cleaning"
            deckDescription6="Rubber Surfaces & Track Fields"
            // deckTitle="Why Orbital"
            deckDescription7="Chemical Free Stripping"
            deckDescription8="70% Reduction in Water Usage"
            deckDescription9="Floor Finish Prep & Recoat"
            brochurePdf="../kodiak-walk-behind-sm.pdf"
            techPdf="../kodiak-tech-specs-03-15-21.pdf"
            partsPdf="https://www.dropbox.com/s/c4h1ep9rf8xszew/k-k25-p-en.pdf?dl=0"
            operatorPdf="https://www.dropbox.com/s/554b7q61rbrrknr/k-k25-op-en.pdf?dl=0"
            hoodPdf="../kodiak-underthehood.pdf"
            mobileDiskImage={DiskImageThumb}
            mobileCylindricalImage={CylindricalImageThumb}
            mobileOrbitalImage={OrbitalImageThumb}
            mobileDiskTitle="Disk"
            mobileCylindricalTitle="Cylindrical"
            mobileOrbitalTitle="Orbital"
            PmPdf="../K25-Series-PM.pdf"
          />
        </K24PageMain>
      </MainLayout>
    </>
  )
}

export default RiderSeriesPage
